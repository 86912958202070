import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { axios } from '../../services/axiosService';
import Axios from 'axios';
import { authActions } from '../auth/authSlice';
import firebase from 'firebase';
import { settingActions } from './settingsSlice';
import { getPasswordErrorIfInsufficient } from '../../utils/helpers';
import { Preferences } from './../../utils/constants';
import { videoAnnotateActions } from '../videoAnnotate/videoAnnotateSlice';
export const updateProfile =
    (
        id: string | undefined,
        firstName: string | undefined,
        lastName: string | undefined,
        username: string | undefined,
        profileImage: string | undefined | null
    ) =>
    async (dispatch: Dispatch) => {
        try {
            const data = {
                firstName: firstName,
                lastName: lastName,
                username: username,
                profileImage: profileImage,
            };
            const response = (await axios.put('/users/' + id, data)).data;
            dispatch(authActions.setDatabaseUser(response));
            toast.success('Profile Updated Successfully');
        } catch (err) {
            console.error(err);
            toast.error(err.message);
        }
    };

export const updatePasswordResetRequired = (id: string) => async (dispatch: Dispatch) => {
    try {
        const data = {
            passwordResetRequired: false,
        };
        const response = (await axios.put('/users/' + id, data)).data;
        dispatch(authActions.setDatabaseUser(response));
        toast.success('Password Changed Successfully');
    } catch (err) {
        console.error(err);
        toast.error(err.message);
    }
};

export const changePassword =
    (
        currentPassword: string,
        newPassword: string,
        options?: {
            onSuccess?: () => void;
            onError?: (err: any) => void;
        }
    ) =>
    async (dispatch: Dispatch) => {
        try {
            const passwordError = getPasswordErrorIfInsufficient(newPassword);
            if (passwordError) {
                return toast.error(passwordError);
            }
            var user = firebase.auth().currentUser;
            var email: string = user?.email as string;
            var cred = firebase.auth.EmailAuthProvider.credential(email, currentPassword);
            user?.reauthenticateWithCredential(cred)
                .then(() => {
                    user?.updatePassword(newPassword)
                        .then(() => {
                            if (options?.onSuccess) {
                                options.onSuccess();
                            } else {
                                toast.success('Password Changed Successfully');
                            }
                        })
                        .catch((error) => {
                            if (options?.onError) {
                                options.onError(error);
                            } else {
                                toast.error(error.message);
                            }
                        });
                })
                .catch((error) => {
                    toast.error(
                        'Something went wrong. Please verify that your current password was entered correctly'
                    );
                });
        } catch (err) {
            console.error(err);
            toast.error(err.message);
        }
    };

export const updatePreference =
    (id: String | undefined, type: Number, value: String) => async (dispatch: Dispatch) => {
        try {
            let code = '';
            let text = '';
            switch (type) {
                case 1:
                    code = Preferences.MESSAGE_WEB_NOTIFICATION;
                    text = 'Push Message Notification ';
                    break;
                case 2:
                    code = Preferences.REQUEST_WEB_NOTIFICATION;
                    text = 'New Contact Request ';
                    break;
                case 3:
                    code = Preferences.MESSAGE_NOTIFICATION;
                    text = 'New Message Push Notification ';
                    break;
                case 4:
                    code = Preferences.REQUEST_NOTIFICATION;
                    text = 'New Contact Request Push Notification ';
                    break;
                case 5:
                    code = Preferences.MESSAGE_EMAIL;
                    text = 'New Message Email ';
                    break;
                case 6:
                    code = Preferences.REQUEST_EMAIL;
                    text = 'New Contact Request Email ';
                    break;
                case 7:
                    code = Preferences.PHOTO;
                    text = 'Photo Layout ';
                    break;
                case 8:
                    code = Preferences.VIDEO;
                    text = 'Video Layout ';
                    break;
                default:
                    break;
            }
            const request = { code: code, value: value };
            const data = (await axios.patch('/user/preference/' + id, request)).data.data;

            switch (type) {
                case 1:
                    dispatch(settingActions.setMessageWebPushNotification(data));
                    break;
                case 2:
                    dispatch(settingActions.setRequestWebPushNotification(data));
                    break;
                case 3:
                    dispatch(settingActions.setMessagePushNotification(data));
                    break;
                case 4:
                    dispatch(settingActions.setRequestPushNotification(data));
                    break;
                case 5:
                    dispatch(settingActions.setMessageEmail(data));
                    break;
                case 6:
                    dispatch(settingActions.setRequestEmail(data));
                    break;
                case 7:
                    dispatch(settingActions.setPhotoPreference(data));
                    break;
                case 8:
                    dispatch(settingActions.setVideoPreference(data));
                    break;
                default:
                    break;
            }
            toast.success(text + 'Preference Changed Successfully');
        } catch (err) {
            console.log(err);
            toast.error(err.message);
        }
    };

export const getPreferences = () => async (dispatch: Dispatch) => {
    try {
        const data = (await axios.get('/user/preferences/All')).data.data;

        const videoPref = data.filter(
            (item: { code: string }) => item.code === Preferences.VIDEO
        )[0];
        dispatch(settingActions.setVideoPreference(videoPref));
        dispatch(
            videoAnnotateActions.setIsVideoFullSize(
                videoPref?.value.toUpperCase() === 'FULL-CANVAS'
            )
        );

        const photoPref = data.filter(
            (item: { code: string }) => item.code === Preferences.PHOTO
        )[0];
        dispatch(settingActions.setPhotoPreference(photoPref));
        dispatch(
            videoAnnotateActions.setIsPhotoFullSize(
                photoPref?.value.toUpperCase() === 'FULL-CANVAS'
            )
        );

        dispatch(
            settingActions.setMessageWebPushNotification(
                data.filter(
                    (item: { code: string }) => item.code === Preferences.REQUEST_WEB_NOTIFICATION
                )[0]
            )
        );
        dispatch(
            settingActions.setRequestWebPushNotification(
                data.filter(
                    (item: { code: string }) => item.code === Preferences.REQUEST_WEB_NOTIFICATION
                )[0]
            )
        );
        dispatch(
            settingActions.setMessagePushNotification(
                data.filter(
                    (item: { code: string }) => item.code === Preferences.MESSAGE_NOTIFICATION
                )[0]
            )
        );
        dispatch(
            settingActions.setRequestPushNotification(
                data.filter(
                    (item: { code: string }) => item.code === Preferences.REQUEST_NOTIFICATION
                )[0]
            )
        );
        dispatch(
            settingActions.setMessageEmail(
                data.filter((item: { code: string }) => item.code === Preferences.MESSAGE_EMAIL)[0]
            )
        );
        dispatch(
            settingActions.setRequestEmail(
                data.filter((item: { code: string }) => item.code === Preferences.REQUEST_EMAIL)[0]
            )
        );
    } catch (err) {
        console.log(err);
        toast.error(err.message);
    }
};

export const getReferrers = (code: string | undefined) => async (dispatch: Dispatch) => {
    try {
        const data = (await axios.get('/user/referred_users?referralCode=' + code)).data.data;
        dispatch(settingActions.setReferrers(data));
    } catch (err) {
        console.log(err);
        toast.error(err.message);
    }
};

export const deleteAccount = () => async (dispatch: Dispatch) => {
    try {
        const response = await axios.delete('/users/delete');

        if (response.data.success) {
            toast.success(response.data.message);
            setTimeout(() => {
                firebase.auth().signOut();
            }, 3000);
        } else {
            toast.error(response.data.message);
        }
    } catch (err) {
        if (Axios.isAxiosError(err) && err.response) {
            const { data } = err.response;
            console.log('Error response body:', data);

            toast.error(data?.message || 'An error occurred.');
        } else {
            console.log(err);
            toast.error(err.message);
        }
    }
};