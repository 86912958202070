import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { axios } from '../../services/axiosService';
import { inboxActions } from './inboxSlice';
import { AppState } from '../../store/appState';
import { Conversation, SendingListItem } from '../../types/Conversation';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const acceptRejectRequest =
    (id: Number, isAccepted: Boolean) => async (dispatch: Dispatch) => {
        try {
            const data = await axios.post('/user/acceptdeny_request', {
                id: id,
                isAccepted,
            });
            if (data.status === 200) {
                isAccepted ? toast.success('Request Accepted') : toast.info('Request Rejected');
            }
        } catch (err) {
            console.error(err);
            toast.error(err.message);
        }
    };

export const updateContactRequests = () => async (dispatch: Dispatch) => {
    try {
        const contactRequests = (await axios.get('/users/contact_requests')).data;
        dispatch(inboxActions.setContactRequests(contactRequests.data));
    } catch (err) {
        console.log(err);
        toast.error(err.message);
    }
};
export const getInboxData = () => async (dispatch: Dispatch) => {
    try {
        const contactRequests = (await axios.get('/users/contact_requests')).data;
        const approvedContacts = (await axios.get('/conversations')).data;
        const organizations = (await axios.get('/organizations')).data;
        dispatch(inboxActions.setContactRequests(contactRequests.data));
        dispatch(inboxActions.setInboxUsers(approvedContacts));
        dispatch(inboxActions.setOrganizations(organizations));
    } catch (err) {
        console.error(err);
        toast.error(err.message);
    }
};
export const getCurrentMessages =
    (conversationId: String, org: Boolean) => async (dispatch: Dispatch) => {
        if (!conversationId) return;
        try {
            dispatch(inboxActions.setCurrentMessagesLoaded(false));
            const messages = (
                await axios.get('/conversations/' + conversationId + '/messages?page=1&pageSize=25')
            ).data;
            let message;
            if (messages.messages.length < 50) {
                message = { messages: messages, loadCompleted: true };
            } else {
                message = { messages: messages, loadCompleted: false };
            }
            if (org) {
                dispatch(inboxActions.setCurrentMessagesOrg(message));
            } else {
                dispatch(inboxActions.setCurrentMessages(message));
            }
        } catch (err) {
            console.log(err);
            // toast.error(err.message);
        }
    };

export const loadMoreMessages =
    (conversationId: String, pageNumber: number, org: Boolean) => async (dispatch: Dispatch) => {
        try {
            const messages = (
                await axios.get(
                    '/conversations/' +
                        conversationId +
                        '/messages?page=' +
                        pageNumber +
                        '&pageSize=10'
                )
            ).data;
            if (messages.messages.length !== 0) {
                let data = { newMessages: messages.messages, complete: false };
                if (org) {
                    dispatch(inboxActions.addMoreMessagesOrg(data));
                    dispatch(inboxActions.setCurrentMessagesLoaded(true));
                } else {
                    dispatch(inboxActions.addMoreMessages(data));
                    dispatch(inboxActions.setCurrentMessagesLoaded(true));
                }
            } else {
                let data = { newMessages: messages.messages, complete: true };
                if (org) {
                    dispatch(inboxActions.addMoreMessagesOrg(data));
                } else {
                    dispatch(inboxActions.addMoreMessages(data));
                }
            }
        } catch (err) {
            console.log(err);
            toast.error(err.message);
        }
    };

export const sendContent =
    (
        conversationId: String,
        content: String,
        userId: String | undefined,
        org: Boolean,
        username: String | undefined,
        profileImage: String | undefined
    ) =>
    async (dispatch: Dispatch) => {
        if (!content) return;
        try {
            const response = (
                await axios.post('/conversations/messages', {
                    conversationId: conversationId,
                    contents: content,
                })
            ).data;
            console.log(dayjs().utc(false).toISOString());
            const message = {
                id: response,
                senderId: userId,
                conversationId: conversationId,
                contents: content,
                flagged: false,
                delete: false,
                sentAt: dayjs().utc().toISOString(),
                user: {
                    shortName: username,
                    profileImage: profileImage,
                },
            };
            if (org) {
                dispatch(inboxActions.addSentMessageOrg(message));
            } else {
                dispatch(inboxActions.addSentMessage(message));
            }
        } catch (err) {
            console.log(err);
            toast.error(err.message);
        }
    };

export const updateConversations = (timeStamp: String) => async (dispatch: Dispatch) => {
    try {
        const organizations = (await axios.get('/organizations')).data;
        dispatch(inboxActions.setOrganizations(organizations));
        const newConversations = (await axios.get('/Conversations/poll?timestamp=' + timeStamp))
            .data;
        dispatch(inboxActions.updateConversations(newConversations));
    } catch (error) {
        console.log(error);
    }
};
export const getConversations = (timeStamp: String) => async (dispatch: Dispatch) => {
    try {
        dispatch(inboxActions.setLoadingOrganizations(true));
        const organizations = (await axios.get('/organizationsAndGroups')).data;
        dispatch(inboxActions.setOrganizations(organizations));
        dispatch(inboxActions.setLoadingOrganizations(false));
        const newConversations = (await axios.get('/Conversations/poll?timestamp=' + timeStamp))
            .data;
        dispatch(inboxActions.updateConversations(newConversations));
    } catch (error) {
        console.log(error);
    }
};
export const getNewMessages =
    (conversationId: String, timeStamp: String, userId: String | undefined, type: Boolean) =>
    async (dispatch: Dispatch) => {
        try {
            if (!conversationId) return;
            const response = (
                await axios.get('/Conversations/' + conversationId + '/poll?timestamp=' + timeStamp)
            ).data;
            const newResponse = response.map((item: any) => {
                return { ...item, user: { shortName: item.shortName } };
            });
            const data = {
                messages: newResponse,
                userId: userId,
            };

            if (type) {
                //Organzation
                dispatch(inboxActions.loadNewMessagesOrg(data));
            } else {
                dispatch(inboxActions.loadNewMessages(data));
            }
        } catch (err) {
            console.log(err);
        }
    };

export const actionOnMessage =
    (conversationId: String, messageId: String, action: String, type: Boolean) =>
    async (dispatch: Dispatch) => {
        try {
            const response = (
                await axios.put(
                    '/conversations/' +
                        conversationId +
                        '/messages/' +
                        messageId +
                        '?action=' +
                        action
                )
            ).data;
            dispatch(
                inboxActions.updateCurrentMessagesAfterModal({
                    messageId: response,
                    action: action,
                    type: type,
                })
            );
            dispatch(inboxActions.setMessageModal({ value: false }));
            toast.success('Action Successful');
        } catch (err) {
            console.log(err);
            toast.error(err.message);
        }
    };

export const unreadCountUpdater = () => async (dispatch: Dispatch) => {
    try {
        dispatch(inboxActions.setUnreadMessages({}));
    } catch (err) {
        console.log(err);
        toast.error(err.message);
    }
};

export const reportAConversation = (id: String) => async (dispatch: Dispatch) => {
    try {
        const response = await axios.put('/conversations/flagall/' + id);
        if (response.status === 200) {
            toast.success('Conversation Reported');
        }
    } catch (err) {
        console.log(err);
        toast.error(err.message);
    }
};

export const removeAConversation = (id: String) => async (dispatch: Dispatch) => {
    try {
        const response = await axios.delete('/conversations/' + id);
        if (response.status === 200) {
            toast.success('Conversation Removed');
            dispatch(inboxActions.updateAfterRemoveConversation(id));
        }
    } catch (err) {
        console.log(err);
        toast.error(err.message);
    }
};

export const loadSendingList =
    (queryString: string) => async (dispatch: Dispatch, getState: () => AppState) => {
        const state = getState();
        const { id: userId } = state.auth.databaseUser!;

        try {
            let conversations: Conversation[];

            if (queryString.length > 3) {
                conversations = (await axios.get(`/users/conversations?searchTerm=${queryString}`))
                    .data;
            } else {
                conversations = (await axios.get(`/conversations`)).data;
            }

            let organizations = (await axios.get(`/organizations`)).data;

            let sendingList = conversations.map((c) => {
                const userList = c.users.filter((u) => u.id !== userId);
                const displayName = userList[0].firstName + ' ' + userList[0].lastName;
                return {
                    conversationId: c.conversation.id,
                    displayName,
                    sent: false,
                    shortName: userList[0].shortName,
                    profileImage: userList[0].profileImage,
                    expandable: false,
                } as SendingListItem;
            });

            const orgList = organizations.reduce((agg: any, o: any) => {
                const groupList = o.groups.map((g: any) => {
                    if (g.role === 'Student') {
                        return {
                            conversationId: g.conversationId,
                            displayName: `${o.name} / ${g.name}`,
                            sent: false,
                            shortName: g.name.substr(0, 1),
                            expandable: false,
                        } as SendingListItem;
                    } else {
                        return {
                            conversationId: g.conversationId,
                            displayName: `${o.name} / ${g.name}`,
                            sent: false,
                            shortName: g.name.substr(0, 1),
                            expandable: true,
                            subItems: g.conversations.map((gc: any) => {
                                return {
                                    conversationId: gc.id,
                                    displayName: `${gc.firstName} ${gc.lastName}`,
                                    sent: false,
                                    shortName: `${gc.firstName.substr(0, 1)}${gc.lastName.substr(
                                        0,
                                        1
                                    )}`,
                                    expandable: false,
                                } as SendingListItem;
                            }),
                        } as SendingListItem;
                    }
                });

                return agg.concat(groupList);
            }, []);

            sendingList = sendingList.concat(orgList);

            sendingList.sort((a, b) => {
                const aLower = a.displayName.toLocaleLowerCase();
                const bLower = b.displayName.toLocaleLowerCase();
                if (aLower < bLower) {
                    return -1;
                } else if (aLower > bLower) {
                    return 1;
                } else {
                    return 0;
                }
            });

            dispatch(inboxActions.setSendingList(sendingList));
        } catch (err) {
            console.error(err);
        } finally {
        }
    };

export const getVideoList = (conversationId: string) => async (dispatch: Dispatch) => {
    try {
        const videoList = (await axios.get('/conversations/' + conversationId + '/details')).data;
        if (videoList) {
            dispatch(inboxActions.setSentVideos(videoList.sentVideos));
            dispatch(inboxActions.setReceivedVideos(videoList.receivedVideos));
        }
    } catch (err) {
        console.error(err);
    }
};

export const flagMessage = (messageId: string) => async (
    dispatch: Dispatch
) => {
    try {
        await axios.put(`/messages/${messageId}/flag`);
        dispatch(inboxActions.flagMessage({ messageId }));
    } catch (err) {
        console.error(err);
    }
};