import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';
import { SendingListItem } from '../../types/Conversation';

interface ContactRequests {
    firstName: string;
    lastName: string;
    username: string;
    userId: string;
    id: number;
}
const initialState = {
    contactRequests: [] as ContactRequests[],
    inboxUsers: [] as any[],
    organizations: [] as any[],
    loadingOrganizations: false as boolean,
    currentMessages: {} as any,
    currentMessagesOrg: {} as any,
    loadCompleted: false as Boolean,
    unreadMessages: 0 as number,
    unreadMessagesOrg: 0 as number,
    groupsConversations: {} as any,

    showMessageAction: false,
    actionMessageId: '' as string,
    actionConversationId: '' as string,
    actionOnOtherUserMessage: false as Boolean,
    actionOnType: false as Boolean,

    setDeleteModal: false as boolean,

    sendingList: [] as SendingListItem[],

    sentVideos: [] as any[],
    receivedVideos: [] as any[],
};

export type InboxAppState = typeof initialState;

export const slice = createSlice<
    InboxAppState,
    {
        setContactRequests: CaseReducer<InboxAppState, PayloadAction<any>>;
        setInboxUsers: CaseReducer<InboxAppState, PayloadAction<any>>;
        setOrganizations: CaseReducer<InboxAppState, PayloadAction<any>>;
        setCurrentMessages: CaseReducer<InboxAppState, PayloadAction<any>>;
        setCurrentMessagesOrg: CaseReducer<InboxAppState, PayloadAction<any>>;
        setCurrentMessagesLoaded: CaseReducer<InboxAppState, PayloadAction<boolean>>;
        addMoreMessages: CaseReducer<InboxAppState, PayloadAction<any>>;
        addMoreMessagesOrg: CaseReducer<InboxAppState, PayloadAction<any>>;
        addSentMessage: CaseReducer<InboxAppState, PayloadAction<any>>;
        setMessageModal: CaseReducer<InboxAppState, PayloadAction<any>>;
        updateCurrentMessagesAfterModal: CaseReducer<InboxAppState, PayloadAction<any>>;
        addSentMessageOrg: CaseReducer<InboxAppState, PayloadAction<any>>;
        loadNewMessages: CaseReducer<InboxAppState, PayloadAction<any>>;
        loadNewMessagesOrg: CaseReducer<InboxAppState, PayloadAction<any>>;
        updateConversations: CaseReducer<InboxAppState, PayloadAction<any>>;
        updateConversationsOrg: CaseReducer<InboxAppState, PayloadAction<any>>;
        updateAfterRemoveConversation: CaseReducer<InboxAppState, PayloadAction<any>>;
        setUnreadMessages: CaseReducer<InboxAppState, PayloadAction<any>>;
        setUnreadMessagesOrg: CaseReducer<InboxAppState, PayloadAction<any>>;
        setConversationOfGroup: CaseReducer<InboxAppState, PayloadAction<any>>;
        setOrganizationGroupConversations: CaseReducer<InboxAppState, PayloadAction<any>>;
        setDeleteModal: CaseReducer<InboxAppState, PayloadAction<any>>;
        setSendingList: CaseReducer<InboxAppState, PayloadAction<SendingListItem[]>>;
        setSentVideos: CaseReducer<InboxAppState, PayloadAction<any[]>>;
        setReceivedVideos: CaseReducer<InboxAppState, PayloadAction<any[]>>;
        setLoadingOrganizations: CaseReducer<InboxAppState, PayloadAction<boolean>>;
        flagMessage: CaseReducer<InboxAppState, PayloadAction<any>>;
    }
>({
    name: 'inbox',
    initialState,
    reducers: {
        setDeleteModal: (state, { payload }) => {
            state.setDeleteModal = payload;
        },
        setConversationOfGroup: (state, { payload }) => {
            state.groupsConversations = payload;
        },
        setContactRequests: (state, { payload }) => {
            state.contactRequests = payload;
        },
        setOrganizationGroupConversations: (state, { payload }) => {
            state.organizations = state.organizations.map((item) => {
                if (item.id === payload.organizationId) {
                    // Find the group that matches payload.id
                    item.groups = item.groups.map((group: any) => {
                        if (group.id === payload.id) {
                            return payload;
                        } else {
                            return group;
                        }
                    });
                    return item;
                } else {
                    return item;
                }
            })
        },
        setLoadingOrganizations: (state, { payload }) => {
            state.loadingOrganizations = payload;
        },
        setInboxUsers: (state, { payload }) => {
            state.inboxUsers = payload;
        },
        setOrganizations: (state, { payload }) => {
            state.organizations = payload;
        },
        setUnreadMessages: (state, { payload }) => {
            state.unreadMessages = 0;
        },
        updateAfterRemoveConversation: (state, { payload }) => {
            state.currentMessages = {};
            state.inboxUsers = state.inboxUsers.filter((item) => {
                if (item.conversation.id === payload) {
                    return false;
                }
                return true;
            });
        },
        setUnreadMessagesOrg: (state, { payload }) => {
            state.unreadMessagesOrg = 0;
        },
        setMessageModal: (state, { payload }) => {
            state.actionOnType = payload.type;
            state.showMessageAction = payload.value;
            state.actionMessageId = payload.messageId;
            state.actionConversationId = payload.conversationId;
            state.actionOnOtherUserMessage = payload.otherUserMessage;
        },
        updateCurrentMessagesAfterModal: (state, { payload }) => {
            if (payload.type) {
                if (payload.action === 'flag') {
                    state.currentMessagesOrg.messages = state.currentMessagesOrg.messages.map(
                        (message: any) => {
                            if (payload.messageId === message.id) {
                                return { ...message, flagged: true };
                            } else {
                                return message;
                            }
                        }
                    );
                } else {
                    state.currentMessagesOrg.messages = state.currentMessagesOrg.messages.filter(
                        (message: any) => {
                            if (payload.messageId === message.id) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    );
                }
            } else {
                if (payload.action === 'flag') {
                    state.currentMessages.messages = state.currentMessages.messages.map(
                        (message: any) => {
                            if (payload.messageId === message.id) {
                                return { ...message, flagged: true };
                            } else {
                                return message;
                            }
                        }
                    );
                } else {
                    state.currentMessages.messages = state.currentMessages.messages.filter(
                        (message: any) => {
                            if (payload.messageId === message.id) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    );
                }
            }
        },
        setCurrentMessages: (state, { payload }) => {
            state.currentMessages = payload.messages;
            state.loadCompleted = payload.loadCompleted;
        },
        setCurrentMessagesOrg: (state, { payload }) => {
            state.currentMessagesOrg = payload.messages;
            state.loadCompleted = payload.loadCompleted;
        },
        setCurrentMessagesLoaded: (state, { payload }) => {
            state.loadCompleted = payload;
        },
        addMoreMessages: (state, { payload }) => {
            state.currentMessages.messages = [
                ...state.currentMessages.messages,
                ...payload.newMessages,
            ];
            state.loadCompleted = payload.complete;
        },
        addMoreMessagesOrg: (state, { payload }) => {
            state.currentMessagesOrg.messages = [
                ...state.currentMessagesOrg.messages,
                ...payload.newMessages,
            ];
            state.loadCompleted = payload.complete;
        },
        updateConversationsOrg: (state, { payload }) => {
            // still
            if (payload.length > 0) {
                payload.map((newOne: any) => {
                    state.groupsConversations.conversations =
                        state.groupsConversations.conversations.map((oldOne: any) => {
                            if (newOne.conversation.id === oldOne.conversation.id) {
                                return newOne;
                            } else {
                                return oldOne;
                            }
                        });
                    return 0;
                });
                state.groupsConversations.conversations =
                    state.groupsConversations.conversations.sort(function (a: any, b: any): number {
                        const dateA = new Date(a.lastMessage.sentAt).getTime();
                        const dateB = new Date(b.lastMessage.sentAt).getTime();
                        return dateB - dateA;
                    });
            }
        },
        updateConversations: (state, { payload }) => {
            if (payload.length > 0) {
                payload.map((newOne: any) => {
                    state.inboxUsers = state.inboxUsers.map((oldOne: any) => {
                        if (newOne.conversation.id === oldOne.conversation.id) {
                            return newOne;
                        } else {
                            return oldOne;
                        }
                    });
                    return 0;
                });
                //Sort by Time
                state.inboxUsers = state.inboxUsers.sort(function (a, b): number {
                    const dateA = new Date(a.lastMessage?.sentAt).getTime();
                    const dateB = new Date(b.lastMessage?.sentAt).getTime();
                    return dateB - dateA;
                });
            }
        },
        addSentMessage: (state, { payload }) => {
            state.currentMessages.messages = [payload, ...state.currentMessages.messages];
            state.inboxUsers = state.inboxUsers.map((item) => {
                if (item.conversation.id === payload.conversationId) {
                    item.lastMessage = payload;
                    return item;
                } else {
                    return item;
                }
            });
        },
        addSentMessageOrg: (state, { payload }) => {
            state.currentMessagesOrg.messages = [payload, ...state.currentMessagesOrg.messages];
            state.groupsConversations.conversations = state.groupsConversations.conversations?.map(
                (item: any) => {
                    if (item.id === payload.conversationId) {
                        item.lastMessage = payload;
                        return item;
                    } else {
                        return item;
                    }
                }
            );
        },
        loadNewMessagesOrg: (state, { payload }) => {
            if (payload.messages.length > 0) {
                const otherUserMessages = payload.messages.filter((item: any) => {
                    return item.senderId !== payload.userId;
                });

                const updatedOtherUserMessages = otherUserMessages.filter((item: any) => {
                    let flag = true;
                    for (let i = 0; i < state.currentMessagesOrg.messages.length; i++) {
                        if (state.currentMessagesOrg.messages[i].id === item.id) {
                            flag = false;
                            break;
                        }
                    }
                    return flag;
                });
                state.unreadMessagesOrg = state.unreadMessagesOrg + updatedOtherUserMessages.length;
                state.currentMessagesOrg.messages = [
                    ...updatedOtherUserMessages,
                    ...state.currentMessagesOrg.messages,
                ];
                // Update Conversation
                if (otherUserMessages.length > 0) {
                    state.groupsConversations.conversations =
                        state.groupsConversations.conversations.map((item: any) => {
                            if (
                                item.id ===
                                payload.messages[payload.messages.length - 1].conversationId
                            ) {
                                item.lastMessage = payload.messages[payload.messages.length - 1];
                                return item;
                            } else {
                                return item;
                            }
                        });
                }
            }
        },
        loadNewMessages: (state, { payload }) => {
            if (payload.messages.length > 0) {
                const otherUserMessages = payload.messages.filter((item: any) => {
                    return item.senderId !== payload.userId;
                });

                const updatedOtherUserMessages = otherUserMessages.filter((item: any) => {
                    let flag = true;
                    for (let i = 0; i < state.currentMessages.messages.length; i++) {
                        if (state.currentMessages.messages[i].id === item.id) {
                            flag = false;
                            break;
                        }
                    }
                    return flag;
                });
                state.unreadMessages = state.unreadMessages + updatedOtherUserMessages.length;
                state.currentMessages.messages = [
                    ...updatedOtherUserMessages,
                    ...state.currentMessages.messages,
                ];

                if (otherUserMessages.length > 0) {
                    state.inboxUsers = state.inboxUsers.map((item) => {
                        if (
                            item.conversation.id ===
                            payload.messages[payload.messages.length - 1].conversationId
                        ) {
                            item.lastMessage = payload.messages[payload.messages.length - 1];
                            return item;
                        } else {
                            return item;
                        }
                    });
                }
            }
        },
        setSendingList: (state, { payload }) => {
            state.sendingList = payload;
        },
        setSentVideos: (state, { payload }) => {
            state.sentVideos = payload;
        },
        setReceivedVideos: (state, { payload }) => {
            state.receivedVideos = payload;
        },
        flagMessage: (state, { payload }) => {
            state.currentMessages.messages = state.currentMessages.messages.map((message: any) => {
                if (message.id === payload.messageId) {
                    return { ...message, flagged: true };
                }
                return message;
            });
        }
    },
});

export const useInboxSelector = buildSubStateSelector<InboxAppState>((state) => state.inbox);
export const inboxActions = slice.actions;
export const inboxReducer = slice.reducer;
