import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './Account.module.scss';
import { Modal } from '../../../components/modal/Modal';
import { ClickableText } from '../../../components/clickableText/ClickableText';
import { Spacer } from '../../../components/spacer/Spacer';
import { useSettingsSelector } from '../settingsSlice';
import {settingActions} from '../settingsSlice';

interface DeleteModalProps {
    onConfirmDelete: () => void;
}

export const DeleteAccountModal: React.FunctionComponent<DeleteModalProps> = ({
    onConfirmDelete,
}) => {
    const dispatch = useDispatch();
    const {isDeleteAccountModalOpen} = useSettingsSelector((state) => state);

    const handleConfirmDelete = async () => {
        await onConfirmDelete();
        dispatch(settingActions.setIsDeleteModalOpen(false));
    };

    return (
        <Modal
            isOpen={isDeleteAccountModalOpen}
            onDimmerClick={() => dispatch(settingActions.setIsDeleteModalOpen(false))}
        >
            <div className={styles.deleteModalHeaderText}>
                <p>Are you sure you with to delete your account?</p>
                <br />
                <p>This action is irreversible. It will:</p>
                <ul>
                    <li>Delete your personal data.</li>
                    <li>Make your content inaccessible for yourself.</li>
                    <li>Cancel your subscription.</li>
                </ul>
            </div>
            <ClickableText color="red" onClick={handleConfirmDelete}>
                Delete Account
            </ClickableText>
            <Spacer horizontal size={80} inlineFlex />
            <ClickableText onClick={() => dispatch(settingActions.setIsDeleteModalOpen(false))}>
                Cancel
            </ClickableText>
        </Modal>
    );
};
